<template>
  <div class="forget-pass">
    <el-row type="flex"
            :gutter="10"
            justify="center">
      <el-col :span="6">
        <el-tabs v-model="activeName"
                 :stretch="true"
                 @tab-click="handleClick">
          <el-tab-pane label="通过邮箱重置"
                       name="first">
            <el-form ref="firstForm"
                     :model="firstForm"
                     :rules="firstRules"
                     label-width="80px">
              <el-form-item label="邮 箱"
                            prop="email">
                <el-input v-model="firstForm.email"></el-input>
              </el-form-item>
              <el-form-item label="验证码"
                            prop="verifyCode">
                <el-input v-model="firstForm.verifyCode">
                  <el-button slot="append"
                             @click="sendCaptchaByEmail">发送验证码</el-button>
                </el-input>
              </el-form-item>
              <el-form-item label="新密码"
                            prop="password">
                <el-input type="password"
                          v-model="firstForm.password"></el-input>
              </el-form-item>

              <el-form-item>
                <el-button type="primary"
                           :loading="loading"
                           @click="firstSubmit">提 交</el-button>
              </el-form-item>
            </el-form>
          </el-tab-pane>

          <el-tab-pane label="通过手机重置"
                       name="second">
            <el-form ref="secondForm"
                     :model="secondForm"
                     :rules="secondRules"
                     label-width="80px">
              <el-form-item label="手机号"
                            prop="mobile">
                <el-input v-model="secondForm.mobile"></el-input>
              </el-form-item>
              <el-form-item label="验证码"
                            prop="verifyCode">
                <el-input v-model="secondForm.verifyCode">
                  <el-button slot="append"
                             @click="sendCaptchaByMobile">发送验证码</el-button>
                </el-input>
              </el-form-item>
              <el-form-item label="新密码"
                            prop="password">
                <el-input type="password"
                          v-model="secondForm.password"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary"
                           :loading="loading"
                           @click="secondSubmit">提 交</el-button>
              </el-form-item>
            </el-form>

          </el-tab-pane>

        </el-tabs>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import _ from 'lodash'
import { sendCaptchaByEmail, resetPasswordByEmail, sendCaptchaByMobile, resetPasswordByMobile } from '@/api/login.js'
import { checkPhone, checkEmail } from '@/utils/validate'

export default {
  name: 'forgetPassword',
  data () {
    return {
      activeName: 'first',
      firstForm: {},
      firstRules: {
        email: [
          { required: true, validator: checkEmail, trigger: 'blur' }
        ],
        verifyCode: [
          { required: true, trigger: 'blur', message: '验证码必须' }
        ],
        password: [
          { required: true, trigger: 'blur', message: '密码不能为空' }
        ]
      },
      secondForm: {},
      secondRules: {
        mobile: [
          { required: true, validator: checkPhone, trigger: 'blur' }
        ],
        verifyCode: [
          { required: true, trigger: 'blur', message: '验证码必须' }
        ],
        password: [
          { required: true, trigger: 'blur', message: '密码不能为空' }
        ]
      },
      loading: false
    }
  },
  methods: {
    handleClick () { },
    // 发送邮箱验证码
    sendCaptchaByEmail () {
      if (!this.firstForm.email || this.firstForm.email === '') {
        this.$message.error('请输入邮箱！')
        return false
      }

      sendCaptchaByEmail(_.trim(this.firstForm.email)).then(res => {
        const { code, data, msg } = res
        if (code === 0) {
          this.$message.success(data)
        } else {
          this.$message.error(msg)
        }
      })
    },
    // 邮箱修改密码提交
    firstSubmit () {
      this.$refs.firstForm.validate(valid => {
        if (!valid) {
          return false
        }

        resetPasswordByEmail(this.firstForm).then(res => {
          const { code, data, msg } = res
          if (code === 0) {
            this.$message.success(data)
            this.$refs.firstForm.resetFields()
          } else {
            this.$message.error(msg)
          }
        })
      })
    },
    // 发送手机验证码
    sendCaptchaByMobile () {
      if (!this.secondForm.mobile || this.secondForm.mobile === '') {
        this.$message.error('请输入手机号！')
        return false
      }

      sendCaptchaByMobile(_.trim(this.secondForm.mobile)).then(res => {
        const { code, data, msg } = res
        if (code === 0) {
          this.$message.success(data)
        } else {
          this.$message.error(msg)
        }
      })
    },
    // 通过手机修改密码
    secondSubmit () {
      this.$refs.secondForm.validate(valid => {
        if (!valid) {
          return false
        }

        resetPasswordByMobile(this.secondForm).then(res => {
          const { code, data, msg } = res
          if (code === 0) {
            this.$message.success(data)
            this.$refs.secondForm.resetFields()
          } else {
            this.$message.error(msg)
          }
        })
      })
    }
  }

}
</script>

<style lang="less" scoped>
.forget-pass {
  margin-top: 100px;
}
</style>
